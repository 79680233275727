$(document).ready(function(){
    //Notification Close Cookies
    $('.clientAlertModal').on('hide.bs.modal', function(){
        var isChecked = $(this).find('#checkClose').is(':checked');
        if( isChecked ){
            var days, date, expires, name;
            name = $(this).attr('id');
            days = $(this).data('close-days');
            if( days ){
                date = new Date();
                date.setDate(date.getDate()+days);
                expires = "expires="+date.toUTCString();
            }else{
                expires = "";
            }
            document.cookie = `${name}=; ${expires}`;
        }
    });

    $('.clientAlert').on('close.bs.alert', function(){
        var days, date, expires, name;
        name = $(this).attr('id');
        days = $(this).data('close-days');
        if( days ){
            date = new Date();
            date.setDate(date.getDate()+days);
            expires = "expires="+date.toUTCString();
        }else{
            expires = "";
        }
        document.cookie = `${name}=; ${expires}`;
    });

    // Modal show first
    $('.clientAlertModal').first().modal('show');

    // Modal show next
    $('.clientAlertModal').on('hidden.bs.modal', function(){
        var nextEl = $(this).next();
        if (nextEl.length && nextEl.hasClass('clientAlertModal')){
            nextEl.modal('show');
        }
    });
});